

























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '../mixins/sectionCore'
import { ButtonAction } from '../contracts/ButtonHeroAction'
import SectionBasic from '../components/SectionBasic.vue'
import BlockButtons from '../components/BlockButtons.vue'

export default defineComponent({
  name: 'SectionButtons',
  components: { BlockButtons, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    actionButtons: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
    countInRow: {
      type: Number,
      default: () => 2,
    },
    spaceBetweenButtons: {
      type: String,
      default: '0.4rem',
    },
    actionButtonsAlignment: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'left',
    },
  },
  setup(props) {
    const styles = computed(() => ({
      '--section-buttons-gap': props.spaceBetweenButtons,
    }))

    return {
      styles,
    }
  },
})
